(function (window, undefined) {
  'use strict';

  /*
  NOTE:
  ------
  PLACE HERE YOUR OWN JAVASCRIPT CODE IF NEEDED
  WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR JAVASCRIPT CODE PLEASE CONSIDER WRITING YOUR SCRIPT HERE.  */

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js');
  }

  $.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
  });

  window.dja_datatable = function (ajax_link, show_buttons = true, elId = '#mytable') {
    if (show_buttons === true) {
        var buttons_list = [
            'csv', 'excel', {
                extend: 'pdfHtml5',
                orientation: 'landscape',
                pageSize: 'LEGAL'
            }, 'print'
        ];
    } else {
        var buttons_list = [];

    }
    var dtable = $(elId).DataTable({
        orderCellsTop: true,
        fixedHeader: true,
        'processing': true,
        'serverSide': true,
        buttons: buttons_list,
        "sDom": "<'row d-flex justify-content-between align-items-center m-1' <'col-lg-6 d-flex align-items-center' l> <'col-lg-6 d-flex align-items-center justify-content-lg-end flex-lg-nowrap flex-wrap pe-lg-1 p-0' B>><'table-responsive't><'d-flex justify-content-between mx-2 row' <'col-sm-12 col-md-6' i> <'col-sm-12 col-md-6' p>>",
        "destroy": true,
        "scrollCollapse": true,
        "lengthMenu": [
            [25, 50, 100, -1],
            [25, 50, 100, "All"]
        ],
        'ajax': {
            url: ajax_link,
            type: 'post',
            error: function () {
                $(".table-error").html("");
                $(elId).append('<tbody class="table-error"><tr><td colspan="4">No data found in the server</td></tr></tbody>');
            },
            data: function (data) {
                $(`${elId} tfoot input, ${elId} tfoot select`).each(function (index) {
                    var n = $(this).attr('name');
                    data[n] = $('[name=' + n + ']').val();
                });
            },
        },
        "drawCallback": function () {
            $("[rel='tooltip']").tooltip({
                html: true,
                container: 'body'
            });
        }
    });


    $(document).on('change keyup', `${elId} tfoot input, ${elId} tfoot select`, function () {
        dtable.ajax.reload(null, false);
    });

    return dtable;
  }



})(window);
